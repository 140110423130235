import { BigNumber, ethers } from 'ethers';
import { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { IStakingState } from '../../App';
import { CHAIN_ID, CONTRACT_ADDRESS, TOKEN_ADDRESS } from '../../constants';
import { CONTRACT_ABI, TOKEN_ABI } from '../../constants/abis';

import { ConnectionContext } from '../../context/Connection';
import { Staking as StakingContract, Token } from '../../types';
import Connection from '../Connection/Connection';
import { Props } from '../Header/Header';

import * as blockchainService from './../../services/blockchainService';
import PendingModal from './PendingModal/PendingModal';

import './Staking.scss';

function Staking({ stakingState, setStakingState, potentialReward, setPotentialReward }: Props) {
  const { wallet, provider, disconnect, setChain } = useContext(ConnectionContext);
  const [contract, setContract] = useState<StakingContract | null>(null);
  const [token, setToken] = useState<Token | null>(null);
  const [changeNetwork, setChangeNetwork] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(true);

  const maxButton = document.querySelector('.max-button') as HTMLInputElement | null;

  useEffect(() => {
    getAPY();
    if (stakingState?.tokenBalance) {
      if (stakingState.tokenBalance.gt(0)) {
        maxButton?.classList.remove('disable');
      } else {
        maxButton?.classList.add('disable');
      }
    }
  }, [stakingState, inputValue]);

  useEffect(() => {
    if (!contract || !token) {
      return;
    }

    handleState();
  }, [contract, token]);

  useEffect(() => {
    if (!contract || !token) {
      return;
    }

    handleState();
    const interval = setInterval(() => {
      getCurrentReward();
    }, 15000);

    return () => {
      clearInterval(interval);
    };
  }, [contract, token]);

  useEffect(() => {
    if (!provider || !wallet?.accounts[0]?.address) {
      setContract(null);
      setToken(null);
      setChangeNetwork(false);
      return;
    }

    let update = true;
    provider.getNetwork().then((network) => {
      if (!update) {
        return;
      }

      if (network.chainId.toString() !== CHAIN_ID) {
        setChangeNetwork(true);
        return;
      } else {
        setChangeNetwork(false);
      }

      setContract(
        blockchainService.getContract(
          CONTRACT_ADDRESS,
          CONTRACT_ABI,
          provider,
          wallet?.accounts[0].address!
        ) as StakingContract
      );

      setToken(
        blockchainService.getContract(TOKEN_ADDRESS, TOKEN_ABI, provider, wallet?.accounts[0].address!) as Token
      );
    });

    return () => {
      update = false;
    };
  }, [provider, wallet?.accounts]);

  function getCurrentReward() {
    if (!contract || !token) {
      return;
    }

    const address = wallet?.accounts[0].address!;

    contract
      .earned(address)
      .then((earned) => {
        setStakingState((prev) => {
          return { ...prev, earned } as IStakingState;
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function handleState() {
    if (!contract || !token) {
      return;
    }

    const address = wallet?.accounts[0].address!;
    Promise.all([
      token.balanceOf(address),
      token.allowance(address, contract.address),
      token.balanceOf(contract.address),
      contract.balances(address),
      contract.earned(address),
      contract.rewardsDuration(),
      contract.rewardPerSecond(),
      contract.totalBalance()
    ]).then((results) => {
      for (const value of results) {
        if (!value) {
          return;
        }

        const [
          tokenBalance,
          tokenAllowance,
          stakingRewardBalance,
          stakedBalance,
          earned,
          rewardsDuration,
          rewardPerSecond,
          totalBalance
        ] = results;
        setStakingState({
          tokenBalance,
          tokenAllowance,
          stakingRewardBalance,
          stakedBalance,
          earned,
          rewardsDuration,
          rewardPerSecond,
          totalBalance
        });
      }
    });
  }

  function handleInput(event: any) {
    if (!event?.target?.value) {
      setInputValue('');
    } else {
      let [base, fraction] = event.target.value.split('.');

      if (fraction?.length > 18) {
        return;
      }

      setInputValue(event.target.value);
    }
  }

  function setChainHandler() {
    // In case of localhost setup
    const rightChainId = CHAIN_ID === '1337' ? 1337 : '0x' + Number(CHAIN_ID).toString(16);
    setChain({ chainId: rightChainId });
  }

  function showError(message: string) {
    setError(message ? message.slice(0, 256) + '...' : 'Something went wrong! Please reload!');
    setTimeout(() => {
      setError(null);
    }, 6000);
  }

  function executeTx(contractCall: () => Promise<ethers.ContractTransaction>) {
    const account = wallet?.accounts[0]?.address;
    if (!contract) {
      return;
    }

    setLoading(true);
    contractCall()
      .then(async (tx) => {
        setShowModal(true);

        return tx.wait().then((_txMined) => {
          setShowSuccess(true);
          setTimeout(() => {
            setShowSuccess(false);
          }, 8000);
        });
      })
      .catch((err) => {
        console.log(err.reason);
        showError(err.reason);
      })
      .finally(() => {
        setShowModal(false);
        handleState().then(() => {
          setLoading(false);
        });
      });
  }

  async function getAPY() {
    if (!stakingState) {
      setPotentialReward({
        reward: '0',
        inPercent: '0'
      });

      return;
    }

    const amount = ethers.utils
      .parseUnits(inputValue.length > 0 ? inputValue : '0', 18)
      .gt(ethers.utils.parseUnits('0'))
      ? inputValue
      : '1000';

    const parsedAmount = ethers.utils.parseUnits(amount, 18);
    if (stakingState.rewardsDuration.eq(ethers.constants.Zero) || parsedAmount.eq(ethers.constants.Zero)) {
      return ethers.BigNumber.from('0');
    }

    let { rewardPerSecond, rewardsDuration, totalBalance: totalStakedBalance, stakingRewardBalance } = stakingState;

    if (rewardPerSecond.eq(ethers.constants.Zero)) {
      const nonAddedReward = stakingRewardBalance.sub(totalStakedBalance);
      if (nonAddedReward.lt(rewardsDuration)) {
        return ethers.BigNumber.from('0');
      }
      rewardPerSecond = nonAddedReward.div(rewardsDuration);
    }

    const projectedReward = rewardPerSecond
      .mul(86400)
      .mul(365)
      .mul(parsedAmount)
      .div(totalStakedBalance.add(parsedAmount));

    const projectedRewardString = ethers.utils.formatUnits(projectedReward, 18);
    const projectedAmountNumber = Number(projectedRewardString);
    const amountNumber = Number(amount);
    const projectedAPR = projectedAmountNumber / amountNumber;
    const projectedAPY = compoundInterest(amountNumber, 1, projectedAPR, 365);

    setPotentialReward({
      reward: projectedAPY.toFixed(2) === 'Infinity' ? '5000000' : projectedAPY.toFixed(2),
      inPercent:
        ((projectedAPY * 100) / amountNumber).toFixed(2) === 'Infinity'
          ? ((5000000 / amountNumber) * 100).toString()
          : ((projectedAPY * 100) / amountNumber).toFixed(2)
    });
  }

  function compoundInterest(
    principal: number,
    time: number,
    rate: number,
    numberOfCompoundingsPerTime: number
  ): number {
    const amount = principal * Math.pow(1 + rate / numberOfCompoundingsPerTime, numberOfCompoundingsPerTime * time);
    const interest = amount - principal;
    return interest;
  }

  function formatAmount(amount: string) {
    if (amount.includes('.')) {
      let [base, fraction] = amount.split('.');
      if (fraction.length > 4) {
        fraction = fraction.slice(0, 4);

        return '~' + base + '.' + fraction;
      }
    }

    return amount;
  }

  function handleAllValue() {
    if (stakingState?.tokenBalance) {
      setInputValue(ethers.utils.formatUnits(stakingState.tokenBalance, 18));
    }
  }

  return (
    <>
      <div className='heading text-center'>
        <h1 className='staking'>
          <span className='frag'>$FRAG</span> Staking
        </h1>
      </div>
      <div className='staking d-flex align-items-center justify-content-center'>
        <Container className='staking-wrapper p-3 rounded m-1 m-sm-5' fluid>
          <Row className='flex-column flex-sm-row'>
            <Col className='staking-form-wrapper d-flex flex-column align-items-center justify-content-center my-4 rounded'>
              <Col className='input d-flex flex-column'>
                <div className='amount d-flex  justify-content-start p-2'>
                  <p>
                    Balance:{' '}
                    {stakingState?.tokenBalance
                      ? formatAmount(ethers.utils.formatUnits(stakingState?.tokenBalance, 18))
                      : 0}{' '}
                    $FRAG
                  </p>
                  <button className='ms-3 max-button disable' onClick={handleAllValue}>
                    MAX
                  </button>
                </div>
                <div className='data'>
                  <input id='input' type='number' onChange={handleInput} value={inputValue} />
                  <p>$FRAG</p>
                </div>
              </Col>
              {changeNetwork ? (
                <Button onClick={setChainHandler} variant='primary' className='mint-btn w-100 mt-3 px-5 py-2'>
                  Switch Network
                </Button>
              ) : loading ? (
                <>
                  <Button variant='primary' className='mint-btn w-100 mt-3 px-5' disabled>
                    Loading...
                  </Button>
                  <PendingModal
                    show={showModal}
                    title='Processing transaction...'
                    body={`Please, do not close this window!`}
                  />
                </>
              ) : wallet && contract ? (
                <>
                  <div className='d-flex w-100 justify-content-start my-2'>
                    <p>
                      Staked balance:{' '}
                      {stakingState?.stakedBalance
                        ? formatAmount(ethers.utils.formatUnits(stakingState?.stakedBalance, 18))
                        : 0}{' '}
                      $FRAG
                    </p>
                  </div>
                  <div className='stake-btn d-flex justify-content-between w-100 pb-3'>
                    {inputValue &&
                    stakingState?.tokenAllowance &&
                    stakingState?.tokenAllowance.lt(ethers.utils.parseUnits(inputValue, 18)) ? (
                      <Button
                        className='mint-btn'
                        onClick={() => executeTx(() => token!.approve(contract.address, ethers.constants.MaxUint256))}
                        disabled={
                          ethers.utils.parseUnits(inputValue.length > 0 ? inputValue : '0', 18).eq(0) ||
                          !stakingState?.tokenBalance ||
                          stakingState.tokenBalance.lt(ethers.utils.parseUnits(inputValue, 18))
                        }
                      >
                        Approve
                      </Button>
                    ) : (
                      <Button
                        className='mint-btn'
                        onClick={() => executeTx(() => contract.stake(ethers.utils.parseUnits(inputValue, 18)))}
                        disabled={
                          ethers.utils.parseUnits(inputValue.length > 0 ? inputValue : '0', 18).eq(0) ||
                          !stakingState?.tokenBalance ||
                          stakingState.tokenBalance.lt(
                            ethers.utils.parseUnits(inputValue.length > 0 ? inputValue : '0', 18)
                          )
                        }
                      >
                        Stake
                      </Button>
                    )}

                    <Button
                      className='mint-btn'
                      onClick={() => executeTx(() => contract.unstake(ethers.utils.parseUnits(inputValue, 18)))}
                      disabled={
                        !stakingState?.stakedBalance ||
                        stakingState.stakedBalance.eq(0) ||
                        !inputValue ||
                        stakingState.stakedBalance.lt(
                          ethers.utils.parseUnits(inputValue.length > 0 ? inputValue : '0', 18)
                        )
                      }
                    >
                      Unstake
                    </Button>
                  </div>
                  <div className='pt-2'>
                    <p>
                      Current Profit:{' '}
                      {stakingState?.earned ? formatAmount(ethers.utils.formatUnits(stakingState?.earned, 18)) : 0}{' '}
                      $FRAG
                    </p>
                  </div>
                  <div className='restake-btn d-flex justify-content-between'>
                    <Button
                      className='mint-btn'
                      onClick={() => executeTx(() => contract.reinvest())}
                      disabled={!stakingState?.earned || stakingState.earned.eq(0)}
                    >
                      Restake
                    </Button>
                    <Button
                      className='mint-btn'
                      onClick={() => executeTx(() => contract.getReward())}
                      disabled={!stakingState?.earned || stakingState.earned.eq(0)}
                    >
                      Withdraw
                    </Button>
                  </div>
                </>
              ) : (
                <Connection />
              )}
              <div className='line'></div>
            </Col>
            <p className='text-center'>Projected Annual Reward: {potentialReward.reward} $FRAG</p>
            <p className='text-center'>APY: {potentialReward.inPercent}%</p>
            {wallet ? (
              ''
            ) : (
              <p className='text-notification text-center'>*Please connect your wallet to see APY.</p>
            )}
            {!error && showSuccess && <p className='text-success text-center'>Successful Transaction!</p>}
            {error && (
              <>
                <p className='error'>Error:</p>
                <p className='error'>{error}</p>
              </>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Staking;
