import { useContext, useEffect, useState, Dispatch, SetStateAction } from 'react';
import { Button } from 'react-bootstrap';
import { BigNumber } from 'ethers';

import { ConnectionContext } from '../../context/Connection';
import { formatAddress } from '../../services/blockchainService';

import './Header.scss';

interface IStakingState {
  tokenBalance: BigNumber;
  tokenAllowance: BigNumber;
  stakingRewardBalance: BigNumber;
  stakedBalance: BigNumber;
  earned: BigNumber;
  rewardsDuration: BigNumber;
  rewardPerSecond: BigNumber;
  totalBalance: BigNumber;
}

export interface Props {
  stakingState: IStakingState | null;
  setStakingState: Dispatch<React.SetStateAction<IStakingState | null>>;
  potentialReward: { reward: string; inPercent: string };
  setPotentialReward: Dispatch<SetStateAction<{ reward: string; inPercent: string }>>;
}

function Header(props: Props) {
  const { wallet, disconnect } = useContext(ConnectionContext);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function handleResize() {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else if (window.innerWidth > 767) {
      setIsMobile(false);
    }
  }

  function onDisconnect() {
    props.setPotentialReward({ reward: '0', inPercent: '0%' });
    props.setStakingState(null);
    disconnect(wallet!);
    window.localStorage.removeItem('connectedWallets');
  }

  return (
    <div className='header d-flex justify-content-center'>
      <div className='header-wrapper d-flex align-items-center px-1 px-md-4'>
        <h1 className='m-0'>
          <a href='https://fragmint.com/'>
            <img
              style={{ width: isMobile ? '82px' : '132px' }}
              src={
                isMobile
                  ? 'https://fragmint.com/images/logo.25221607810af566e9b2001e0c400e58f84ca6428913b7db1136d96b5b1f2740.svg'
                  : 'https://fragmint.com/images/logo.25221607810af566e9b2001e0c400e58f84ca6428913b7db1136d96b5b1f2740.svg'
              }
              alt='fragmint'
            />
          </a>
        </h1>
        {wallet?.accounts[0] && (
          <div className='d-flex align-items-center'>
            <p className='neutral d-inline-block m-2 text-center'>
              Account: {formatAddress(wallet?.accounts[0].address, 6, 5)}
            </p>

            <Button onClick={onDisconnect} variant='secondary' className='disconnect d-inline-block px-2 py-1'>
              Disconnect
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
