import { createContext, useEffect, useState } from 'react';

import { ConnectOptions, WalletState } from '@web3-onboard/core';
import { initWeb3Onboard } from '../services/connectionService';
import { useConnectWallet, useSetChain, useWallets } from '@web3-onboard/react';
import { ethers } from 'ethers';

interface IConnectionContext {
  wallet: WalletState | null;
  connect: (options?: ConnectOptions | undefined) => Promise<WalletState[]>;
  disconnect: (wallet: any) => Promise<WalletState[]>;
  setChain: any;
  connecting: boolean;
  provider: ethers.providers.Web3Provider | null;
}

type Props = {
  children?: JSX.Element | JSX.Element[];
};

const defaultValue: IConnectionContext = {
  wallet: null,
  connect: () => Promise.resolve([]),
  disconnect: () => Promise.resolve([]),
  setChain: () => Promise.resolve(),
  connecting: false,
  provider: null
};

export const ConnectionContext = createContext(defaultValue);

export const ConnectionProvider = ({ children }: Props) => {
  const [web3Onboard, setWeb3Onboard] = useState<any>(null);
  const [provider, setProvider] = useState<ethers.providers.Web3Provider | null>(null);

  const [
    {
      wallet, // the wallet that has been connected or null if not yet connected
      connecting // boolean indicating if connection is in progress
    },
    connect, // function to call to initiate user to connect wallet
    disconnect // function to call to with wallet<DisconnectOptions> to disconnect wallet
  ] = useConnectWallet();
  const connectedWallets = useWallets();

  const [
    _chain,
    setChain // function to call to initiate user to switch chains in their wallet
  ] = useSetChain();

  useEffect(() => {
    setWeb3Onboard(initWeb3Onboard);
  }, []);

  useEffect(() => {
    if (!connectedWallets.length) return;

    const connectedWalletsLabelArray = connectedWallets.map(({ label }) => label);
    window.localStorage.setItem('connectedWallets', JSON.stringify(connectedWalletsLabelArray));
  }, [connectedWallets, wallet]);

  useEffect(() => {
    if (!wallet?.provider) {
      setProvider(null);
    } else {
      setProvider(new ethers.providers.Web3Provider(wallet.provider, 'any'));
    }
  }, [wallet]);

  useEffect(() => {
    const previouslyConnectedWallets = JSON.parse(window.localStorage.getItem('connectedWallets')!);

    if (previouslyConnectedWallets?.length) {
      connectWallet(previouslyConnectedWallets);
    }
  }, [web3Onboard, connect]);

  async function connectWallet(previouslyConnectedWallets: Array<string>) {
    await connect({ autoSelect: { disableModals: false, label: previouslyConnectedWallets[0] } })
    .catch((err) =>
      console.log(err)
    );
  }

  return (
    <ConnectionContext.Provider value={{ connect, wallet, connecting, provider, disconnect, setChain }}>
      {children}
    </ConnectionContext.Provider>
  );
};
