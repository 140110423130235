import { useContext } from 'react';
import { Button } from 'react-bootstrap';

import { ConnectionContext } from '../../context/Connection';

function Connection() {
  const { connect, connecting } = useContext(ConnectionContext);

  function onConnect() {
    connect().catch((err) => console.log(err));
  }

  if (connecting) {
    return <div />;
  } else {
    return (
      <Button className='mint-btn w-100 mt-3 px-5 py-2' onClick={onConnect}>
        Connect Wallet
      </Button>
    );
  }
}

export default Connection;
