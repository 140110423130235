import { Contract, ethers } from 'ethers';

export function getContract(address: string, ABI: any, provider: ethers.providers.Web3Provider, account: string) {
  if (!isAddress(address) || address === ethers.constants.AddressZero) {
    throw new Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(address, ABI, getProviderOrSigner(provider, account));
}

function isAddress(value: string) {
  try {
    return ethers.utils.getAddress(value);
  } catch {
    return false;
  }
}

function getProviderOrSigner(provider: ethers.providers.Web3Provider, account: string) {
  return account ? getSigner(provider, account) : provider;
}

function getSigner(provider: ethers.providers.Web3Provider, account: string) {
  return provider.getSigner(account).connectUnchecked();
}

export const formatAddress = (string: string, startSymbols: number, endSymbols: number): string => {
  return `${string.substr(0, startSymbols)}...${string.substr(string.length - endSymbols, string.length)}`;
};
