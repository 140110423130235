import { useState } from 'react';
import { BigNumber } from 'ethers';

import Staking from './components/Staking/Staking';

import './App.scss';
import { ConnectionProvider } from './context/Connection';
import Header, { Props } from './components/Header/Header';

export interface IStakingState {
  tokenBalance: BigNumber;
  tokenAllowance: BigNumber;
  stakingRewardBalance: BigNumber;
  stakedBalance: BigNumber;
  earned: BigNumber;
  rewardsDuration: BigNumber;
  rewardPerSecond: BigNumber;
  totalBalance: BigNumber;
}

function App() {
  const [stakingState, setStakingState] = useState<IStakingState | null>(null);
  const [potentialReward, setPotentialReward] = useState<{ reward: string; inPercent: string }>({
    reward: '0',
    inPercent: '0%'
  });

  const props: Props = {
    stakingState,
    setStakingState,
    potentialReward,
    setPotentialReward
  };

  return (
    <div className='App'>
      <ConnectionProvider>
        <Header {...props} />
        <Staking {...props} />
      </ConnectionProvider>
    </div>
  );
}

export default App;
