import injectedModule from '@web3-onboard/injected-wallets';
import walletConnectModule from '@web3-onboard/walletconnect';
import { init } from '@web3-onboard/react';

import { PROVIDER_API_KEY } from './../constants';

const injected = injectedModule();
const walletConnect = walletConnectModule();

export const initWeb3Onboard = init({
  wallets: [injected, walletConnect],
  chains: [
    {
      id: '0x1',
      token: 'ETH',
      label: 'Ethereum Mainnet',
      rpcUrl: `https://mainnet.infura.io/v3/${PROVIDER_API_KEY}`
    },
    {
      id: 1337 as any,
      token: 'ETH',
      label: 'Hardhat',
      rpcUrl: 'http://127.0.0.1:8545/'
    },
    {
      id: '0x38',
      token: 'BNB',
      label: 'Binance',
      rpcUrl: 'https://bsc-dataseed.binance.org/'
    },
    {
      id: '0x61',
      token: 'tBNB',
      label: 'Binance Testnet',
      rpcUrl: 'data-seed-prebsc-2-s2.binance.org:8545'
    }
  ],
  accountCenter: {
    desktop: {
      enabled: false
    },
    mobile: {
      enabled: false
    }
  },
  appMetadata: {
    name: 'FRAG Staking',
    icon: 'https://fragmint.com/images/logo.25221607810af566e9b2001e0c400e58f84ca6428913b7db1136d96b5b1f2740.svg',
    logo: 'https://fragmint.com/images/logo.25221607810af566e9b2001e0c400e58f84ca6428913b7db1136d96b5b1f2740.svg',
    description: 'FRAG Staking'
  }
});
